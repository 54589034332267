<template>
  <form class="form" @submit.prevent="onSubmit">
    <BasePreloader v-if="pending"
                   style="top: -2rem"/>
    <BaseInput v-model="inputs.name.value"
               :error="inputs.name.error"
               :label="$t(`Ім'я`)"
               type="text"
               class="form__input"/>
    <BaseInput :label="$t('Телефон')"
               v-model="inputs.phone.value"
               :error="inputs.phone.error"
               :mask="{mask: '+{38} ({\\0}#0)-000-00-00',
                      definitions: {'#': /[1-9]/},
                      unmask: true
                    }"
               type="tel"
               class="form__input"/>
    <AlertInline :value="overallErrorsMix" class="_warning"/>
    <div class="form__footer">
      <button class="form__btn  base-btn" type="submit">
        {{ $t('Відправити') }}
      </button>
    </div>
  </form>
</template>

<script>
  import formMixins from '../mixins/formMixins'
  import AlertInline from '@/components/AlertInline'
  import {http} from '@/axios'
  import {mapGetters} from 'vuex'

  export default {
    name: 'CallBack',
    components: {AlertInline},
    mixins: [formMixins],
    data() {
      return {
        pending: false,
        inputs: {
          name: {
            value: null,
            required: true
          },
          phone: {
            value: null,
            required: true
          },
        }
      }
    },
    computed: {
      ...mapGetters('user', ['isLogged']),
    },
    methods: {
      onSubmit() {
        this.resetErrorsMix(this.inputs)
        const sendData = this.createSendDataMix(this.inputs)
        if (!sendData) return
        this.pending = true
        http.post('/api/callback', sendData).then(() => {
          this.$vModal.close('call-back')
          this.$vModal.open('message', {
            message: this.$t('Ваш запит відправлено, очікуйте на дзвінок')
          })
        }).catch(e => {
          this.handlerErrorMix(e, this.inputs)
        }).finally(() => {
          this.pending = false
        })
      },
      setInput([key, value]) {
        this.inputs[key].value = value
        if (this.inputs[key].error) {
          this.inputs[key].error = null
        }
      },
    },
    mounted() {
      if (this.isLogged) {
        this.$store.dispatch('user/load').then((profile) => {
          if (profile.name) {
            this.setInput(['name', profile.name])
          }
          if (profile.phone) {
            this.setInput(['phone', profile.phone.replace(/^380|^\+380|^0/g, '')])
          }
        })
      }
    }
  }
</script>

<style>

</style>